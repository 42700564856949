import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReactLoading from "react-loading";
import { UserAuth } from "../../context/AppContext";
import DataTable from "../../components/DataTable/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import DataTableDay from "../../components/DataTable/DataTableDay";

const day = ["day_one_attendance", "day_one_attendance", "day_two_attendance"];
export const DaySummary = () => {
  const { id } = useParams();
  const { accountTickets } = UserAuth();

  const navigate = useNavigate();

  if (!["1", "2"].some((e) => e === id)) {
    navigate("/view-day/1");
  }

  if (accountTickets === "init") {
    return (
      <div className="grid justify-center items-center h-96">
        <ReactLoading type="bubbles" color="#313192" height={150} width={150} />
      </div>
    );
  }

  if (accountTickets.length === 0) {
    return (
      <div className="grid justify-center items-center h-96">
        No Data to show as of this moment
      </div>
    );
  }

  // flatted tickets will check if the day is scanned, it is scanned if attendance has keys and the value of the keys is not false
  const flattedTickets = accountTickets.filter((e) =>
    Object.keys(e.attendance || {}).some((k) => !!e.attendance[k])
  );
  let attendanceTicket = [];
  flattedTickets.forEach((e) => {
    Object.keys(e.attendance || {}).forEach((k) => {
      if (e.attendance[k] && k.includes(id == 1 ? "one" : "two")) {
        attendanceTicket.push({
          ...e,
          dateScanned: e.attendance[k].dateScanned,
          approvedBy: e.attendance[k].approvedBy,
          ticket_key: k,
          ticket_key_id: k.split("-")[0] + "-" + k.split("-")[1],
        });
      }
    });
  });

  const ticketLists = accountTickets
    .map((e) => e.no_of_tickets)
    .reduce((a, b) => a + b, 0);

  const ticketListsChurches = attendanceTicket.map((e) => e.church_name);

  console.log(ticketListsChurches);
  const stats = {
    totalScanned: attendanceTicket.length,
    totalUnscanned: ticketLists - attendanceTicket.length,
    totalTickets: ticketLists,
  };

  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-8 row-gap-8">
          <div className="grid gap-8 lg:col-span-2">
            <div>
              <p className="mb-2 text-lg font-bold font-sans text-4xl">
                Day {id} Quick Overview
              </p>
              <p className="text-gray-700">
                Here is the realtime overview of the statistics.
              </p>
            </div>
          </div>
          <div className="grid border divide-y rounded lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
            <div className="flex flex-col gap-4 justify-between p-10">
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Scanned Day {id}
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalScanned}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Unscanned:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalUnscanned}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Tickets:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalTickets}
                </p>
              </div>
            </div>
          </div>
        </div>

        <DataTableDay ticketsList={attendanceTicket} dayStr={day[id]} />
      </div>
    </>
  );
};
