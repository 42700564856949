import './App.css';
import Header from './components/Header';
import Banner from './components/Banner';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import { AuthProvider } from './context/AppContext';
import { Routes, Route } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { Homepage } from './pages/Homepage';
import { MyTicket } from './pages/MyTicket';
import { NewTicket } from './pages/NewTicket';
import ProtectedPage from './components/ProtectedPage/ProtectedPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyTicketWrapper from './pages/MyTicketWrapper/MyTicketWrapper';
import ViewTicket from './pages/ViewTicket/ViewTicket';
import FAQ from './pages/FAQ/FAQ';
import GuidePage from './pages/GuidePage/GuidePage';
import ViewTicketPDF from './pages/ViewTicketPDF/ViewTicketPDF';
import NotFound from './components/NotFound/NotFound';
import PageBanner from './components/PageBanner/PageBanner';
import ScanCode, { ScanCodeResult } from './pages/ScanCode/ScanCode';
import { DaySummary } from './pages/DaySummary';

function App() {

  return (
    <div className="App">
      <AuthProvider>
        <Header />
        <Routes>
          <Route element={<ProtectedPage></ProtectedPage>} >
            <Route path="/" element={<Homepage></Homepage>} />
            <Route path="/view-ticket/:id" element={<ViewTicket></ViewTicket>} />
            <Route path="/view-pdf/:id" element={<ViewTicketPDF></ViewTicketPDF>} />
            <Route path="/scan-code" element={<ScanCode></ScanCode>} />
            <Route path="/scan-code/:id" element={<ScanCodeResult></ScanCodeResult>} />
            <Route path="/view-day/:id" element={<DaySummary></DaySummary>} />
          </Route>
          <Route path="*" element={<>
            <NotFound /></>} />
          <Route />
        </Routes>
        <Footer />
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>
  );
}

export default App;
