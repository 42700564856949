import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { FormGroup, TextField } from "@mui/material";
import {
  DocumentScanner,
  PhotoCamera,
  ScannerRounded,
} from "@mui/icons-material";
import PageviewIcon from "@mui/icons-material/Pageview";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AppContext";
import { uuid } from "uuidv4";
import { uniqueId } from "lodash";
import { useState } from "react";
import { useEffect } from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "ticketId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: true,
    label: "Registered By",
  },
  {
    id: "church_name",
    numeric: true,
    disablePadding: true,
    label: "Church",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dateAdded",
    numeric: true,
    disablePadding: false,
    label: "Date Registered",
  },
  {
    id: "ticketNum",
    numeric: true,
    disablePadding: false,
    label: "No. of Tickets",
  },
  {
    id: "ticketNames",
    numeric: true,
    disablePadding: false,
    label: "Ticket Name/s",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span className="font-sans font-semibold text-md">
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const { searchValue, setSearchValue } = UserAuth();

  return (
    <div className="flex justify-between">
      <div>
        <p className="mb-2 text-lg font-bold font-sans text-4xl">
          Ticket Orders:
        </p>
      </div>

      <Tooltip title="Filter list">
        <div>
          <TextField
            name="search"
            placeholder="Search"
            value={searchValue}
            onChange={(ev) => setSearchValue(ev.currentTarget.value)}
            label={"Search keyword"}
          />
        </div>
      </Tooltip>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DataTable({ tickets }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("dateAdded");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { searchValue } = UserAuth();

  const navigate = useNavigate();
  const [showPendingPayment, setShowPendingPayment] = useState(true);
  const [showPendingApproval, setShowPendingApproval] = useState(true);
  const [showPaid, setShowPaid] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      tickets
        .map((tix) => ({
          ticketId: tix.ticket_id,
          dateAdded:
            new Date(tix.dateAdded?.seconds * 1000)
              .toISOString()
              .split("T")[0] +
            " - " +
            new Date(tix.dateAdded?.seconds * 1000).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
          email: tix.email,
          status: tix.status,
          ticketNum: tix.no_of_tickets,
          ticketNames: tix.full_name,
          action: "",
          status_code: tix.status_code,
          church_name: tix.church_name,
          others_church_name: tix.others_church_name,
        }))
        .filter((row) => {
          if (searchValue) {
            return (
              row.ticketId.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.ticketNames
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              row.email.toLowerCase().includes(searchValue.toLowerCase())
            );
          }
          return true;
        })
        .filter((row) => {
          return !showPendingPayment ? row.status_code !== "100" : true;
        })
        .filter((row) => {
          return !showPendingApproval ? row.status_code !== "101" : true;
        })
        .filter((row) => {
          return !showPaid ? row.status_code !== "102" : true;
        })
    );
    console.log(showPendingApproval, showPendingPayment, showPaid);
  }, [tickets, searchValue, showPendingPayment, showPendingApproval, showPaid]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }} className="p-12 shadow-md">
      <div style={{ width: "100%" }}>
        <EnhancedTableToolbar numSelected={selected.length} />

        <FormGroup style={{ display: "flex", flexDirection: "row-reverse" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPendingPayment}
                onChange={() => setShowPendingPayment((prev) => !prev)}
                name="gilad"
              />
            }
            label="Show Pending Payment"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPendingApproval}
                onChange={() => setShowPendingApproval((prev) => !prev)}
                name="gilad"
              />
            }
            label="Show Pending Approval"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPaid}
                onChange={() => setShowPaid((prev) => !prev)}
                name="gilad"
              />
            }
            label="Show Paid"
          />
        </FormGroup>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name + row.ticketId}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.ticketId}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">
                        {row.church_name || "-"}
                        {row.others_church_name
                          ? ", " + row.others_church_name
                          : ""}
                      </TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.dateAdded}</TableCell>
                      <TableCell align="right">{row.ticketNum}</TableCell>
                      <TableCell align="right">
                        {row.ticketNames.split("\n").map((el) => (
                          <p key={el + uniqueId()}>{el}</p>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        <Box>
                          <IconButton
                            color="primary"
                            aria-label="view ticket"
                            component="label"
                            onClick={() => {
                              navigate("view-ticket/" + row.ticketId);
                            }}
                          >
                            <PageviewIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            aria-label="scan ticket"
                            component="label"
                            onClick={() => {
                              navigate("scan-code/" + row.ticketId);
                            }}
                          >
                            <DocumentScanner />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Box>
  );
}
