import React from "react";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserAuth } from "../../context/AppContext";
import {
  Page as PDFPage,
  Text,
  Document as PDFDocument,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
  PDFDownloadLink,
  View,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import dataUriToBuffer from "data-uri-to-buffer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingLeft: 100,
    paddingRight: 100,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 10,
  },
  textTitle: {
    margin: 8,
    marginLeft: 50,
    marginRight: 50,
    textAlign: "center",
    fontSize: 14,
  },
  line: {
    marginBottom: 6,
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
  },
  text: {
    margin: 6,
    marginLeft: 50,
    marginRight: 50,
    fontSize: 11,
    textAlign: "left",
  },
  image: {
    width: 150,
    display: "flex",
    alignSelf: "center",
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const TicketPDF = ({ ticketList }) => {
  return <PDFDocument>{ticketList.map((ticket) => ticket)}</PDFDocument>;
};

export default function ViewTicketPDF() {
  const { id } = useParams();
  const { accountTickets } = UserAuth();
  const tickets =
    (accountTickets !== "init" &&
      accountTickets.find((ticket) => ticket.ticket_id === id)) ||
    [];
  let components = null;
  let ticketList = [];

  if (tickets.no_of_tickets > 0) {
    for (let x = 0; x < tickets.no_of_tickets; x++) {
      const ticket = tickets;
      let qrCode = "";
      const tixId =
        ticket.ticket_id + "-" + (x + 1).toString().padStart(3, "000");

      QRCode.toDataURL(tixId, { width: 300 }, function (err, url) {
        qrCode = url;
      });

      ticketList.push(
        <PDFPage key={ticket.ticket_id} wrap={false} style={styles.body}>
          <Text style={styles.header} fixed>
            ~ IDMC CROSSWorld Conference 2023 ~
          </Text>
          <View style={styles.headerInfo}>
            <Image
              style={styles.image}
              src={{ data: dataUriToBuffer(qrCode), format: "png" }}
            />
            <View>
              <Text style={styles.title}>Ticket ID: {tixId}</Text>
              <Text style={styles.author}>
                Ticket Holder Name: {ticket.full_name}
              </Text>
            </View>
          </View>
          <Text style={styles.line}>
            -----------------------------------------------------------------------------------------------------------
          </Text>
          <Text style={styles.textTitle}>Ticket Reminder</Text>
          <Text style={styles.text}>
            Tickets for the IDMC CROSSWorld 2023 Conference cannot be refunded,
            but they can be transferred to another person.
          </Text>
          <Text style={styles.text}>
            Bring and present your conference ticket to the registration booth
            on day 1 and day 2 of the conference.
          </Text>
          <Text style={styles.text}>
            The policy states that entry to the conference is only permitted
            with a valid Conference Ticket or QR code
          </Text>
          <Text style={styles.text}>
            Upon arrival on Day 1 and Day 2 of the conference, please present
            your QR code at the registration table for scanning.
          </Text>
          <Text style={styles.text}>
            The conference fee includes access to all plenary sessions and a
            conference ID. It is mandatory to wear your Conference ID at all
            times while inside the conference premises.
          </Text>
          <Text style={styles.text}>
            Please be aware that children aged 12 and below, including infants
            in arms, will not be allowed to enter the conference premises.
          </Text>
          <Text style={styles.text}>
            Kindly note that meals will not be provided during the conference.
          </Text>
          <Text style={styles.text}>
            Each ticket is valid for one person's use at a time.
          </Text>
          <Text style={styles.text}>
            Eating inside the conference hall is strictly prohibited.
          </Text>
          <Text style={styles.text}>
            If you have any questions or concerns regarding the registration,
            please contact us at christenthroned@gmail.com or call (044) 308
            8622 or visit idmccrossworld.com
          </Text>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </PDFPage>
      );
    }
    components = (
      <div className="pdfTicketContainer">
        <PDFViewer width="100%" height="600px">
          <TicketPDF ticketList={ticketList} />
        </PDFViewer>
      </div>
    );
  }
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <NavLink
          to={"/view-ticket/" + tickets.ticket_id}
          aria-label=""
          className="inline-flex mb-8 items-center text-sm font-semibold transition-colors duration-200 text-blue-accent-400 hover:text-blue-800"
        >
          <ArrowBackIcon style={{ marginRight: "12px" }} /> Back to ticket
          details
        </NavLink>
        <br />
        {tickets?.is_paid && (
          <>
            <div className="mb-8 flex justify-end">
              <PDFDownloadLink
                document={<TicketPDF ticketList={ticketList} />}
                fileName={`idmc-conference-ticket-${tickets?.ticket_id}.pdf`}
                className='px-5 py-3 ml-auto text-sm font-medium transition rounded-lg border-2 border-transparent bg-blue-accent-400 text-white hover:border-2 hover:border-blue-accent-400 focus:outline-none focus:ring"'
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download PDF"
                }
              </PDFDownloadLink>
            </div>
            {components}
          </>
        )}
        {!tickets?.is_paid && <div>You are not allowed to view this page</div>}
      </div>
    </>
  );
}
