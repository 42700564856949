import React from "react";
import Banner from "../../components/Banner/Banner";
import ConferenceSchedule from "../../components/ConferenceSchedule/ConferenceSchedule";
import { IntroBanner } from "../../components/IntroBanner/IntroBanner";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReactLoading from "react-loading";
import { UserAuth } from "../../context/AppContext";
import DataTable from "../../components/DataTable/DataTable";

export const Homepage = () => {
  const { accountTickets } = UserAuth();

  if (accountTickets === "init") {
    return (
      <div className="grid justify-center items-center h-96">
        <ReactLoading type="bubbles" color="#313192" height={150} width={150} />
      </div>
    );
  }

  if (accountTickets.length === 0) {
    return (
      <div className="grid justify-center items-center h-96">
        No Data to show as of this moment
      </div>
    );
  }

  const stats = {
    totalOrder: accountTickets.length,
    totalTickets: accountTickets.reduce(
      (curr, prev) => (curr += prev.no_of_tickets),
      0
    ),
    totalPaidTickets: accountTickets
      .filter((t) => t.is_paid)
      .reduce((curr, prev) => (curr += prev.no_of_tickets), 0),
    totalPendingApproval:
      accountTickets.filter((t) => t.status_code === "101")?.length || 0,
    totalPendingUpload:
      accountTickets.filter((t) => t.status_code === "100")?.length || 0,
  };

  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-8 row-gap-8">
          <div className="grid gap-8 lg:col-span-2">
            <div>
              <p className="mb-2 text-lg font-bold font-sans text-4xl">
                Quick Overview
              </p>
              <p className="text-gray-700">
                Here is the realtime overview of the statistics.
              </p>
            </div>
          </div>
          <div className="grid border divide-y rounded lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
            <div className="flex flex-col gap-4 justify-between p-10">
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Orders:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalOrder}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Tickets:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalTickets}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Paid Ticket:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalPaidTickets}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4  justify-between p-10">
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Pending for Approval Order:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalPendingApproval}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base">
                  Total Pending for Upload Receipt:
                </p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl">
                  {stats.totalPendingUpload}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold text-gray-800 sm:text-base"></p>
                <p className="text-2xl font-bold text-blue-accent-400 sm:text-3xl"></p>
              </div>
            </div>
          </div>
        </div>

        <DataTable tickets={accountTickets} />
      </div>
    </>
  );
};
