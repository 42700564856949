import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserAuth } from "../../context/AppContext";
import QRCode from "react-qr-code";
import {
  Alert,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import { storage } from "../../firebase/";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { getMessageByStatusCode } from "./utils";
import { PaymentGuide } from "../../components/Guide/Guide";
import NotFound from "../../components/NotFound/NotFound";
import { CheckCircle } from "@mui/icons-material";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function ViewTicket() {
  const { id } = useParams();
  const { accountTickets, updateApproveStatus, showToast, showWarnToast } =
    UserAuth();
  const [progresspercent, setProgresspercent] = useState(0);
  const [submitText, setSubmitText] = useState("Approve this transaction");
  const [uploadStatus, setUploadStatus] = useState(
    "Uploading Image, Please wait..."
  );

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openPaymentGuideline, setOpenPaymentGuideline] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const descriptionElementRef = React.useRef(null);
  const tickets =
    (accountTickets !== "init" &&
      accountTickets.find((ticket) => ticket.ticket_id === id)) ||
    [];

  const [files, setFiles] = useState([]);
  const [refNo, setRefNo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [adminRemarks, setAdminRemarks] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleApproveRequest = async (
    refNo,
    modeOfPayment,
    adminRemarks,
    approvedBy
  ) => {
    setSubmitText("Submitting...");
    await updateApproveStatus(tickets, {
      refNo,
      modeOfPayment,
      adminRemarks,
      approvedBy,
    });
    showToast("Successfully approved and submitted.");
    setOpenUploadDialog(false);
  };

  React.useEffect(() => {
    if (openUploadDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openUploadDialog]);

  const { title, subtitle } = getMessageByStatusCode(tickets?.status_code);

  if (tickets.length === 0) {
    return <NotFound />;
  }

  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <NavLink
          to={"/"}
          aria-label=""
          className="inline-flex mb-8 items-center text-sm font-semibold transition-colors duration-200 text-blue-accent-400 hover:text-blue-800"
        >
          <ArrowBackIcon style={{ marginRight: "12px" }} /> Back to order ticket
          list
        </NavLink>
        <br />

        <div className=" grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
          <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
            <div className="relative p-5 bg-white rounded-sm">
              <div>
                <span className="text-xs text-gray-700 uppercase ">
                  Order Ticket ID
                </span>
              </div>
              <span className="text-lg font-semibold">{tickets.ticket_id}</span>
            </div>
          </div>
          <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
            <div className="relative p-5 bg-white rounded-sm">
              <div>
                <span className="text-xs text-gray-700 uppercase ">Status</span>
              </div>
              <span className="text-lg font-semibold">{tickets.status}</span>
            </div>
          </div>
          <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
            <div className="relative p-5 bg-white rounded-sm">
              <div>
                <span className="text-xs text-gray-700 uppercase ">
                  Total No of Tickets
                </span>
              </div>
              <span className="text-lg font-semibold">
                {tickets.no_of_tickets}
              </span>
            </div>
          </div>
          <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
            <div className="relative p-5 bg-white rounded-sm">
              <div>
                <span className="text-xs text-gray-700 uppercase ">
                  Total Price
                </span>
              </div>
              <span className="text-lg font-semibold">
                Php {tickets.no_of_tickets * tickets.baseAmount}.00
              </span>
            </div>
          </div>
        </div>
        <header className="bg-gray-50 mb-8">
          <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-12 sm:px-6 lg:px-8">
            <div className="sm:justify-between sm:items-center md:flex gap-12">
              <div className="text-center sm:text-left">
                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                  {title}
                </h1>
                <p className="mt-1.5 text-sm text-gray-700 max-w-xl">
                  {subtitle}
                  {tickets?.status_code === "102" && (
                    <p>
                      <br />
                      <b>Note:</b> Bring and present your conference ticket to
                      the registration booth on day 1 and day 2 of the
                      conference. A “No conference ticket or QR code, No Entry”
                      policy will be observed.
                    </p>
                  )}
                </p>
              </div>

              <div className="flex flex-col gap-4 mt-4 sm:flex-row sm:mt-0 sm:items-center">
                <button
                  className="block px-5 py-3 text-sm font-medium text-white transition bg-blue-accent-700 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring"
                  type="button"
                  onClick={() => setOpenUploadDialog(true)}
                >
                  {tickets?.transaction_img_src ? "View and " : ""}Approve
                  Transaction
                </button>
              </div>
            </div>
          </div>
        </header>
        <header className="">
          <div className="max-w-screen-xl mx-auto sm:pb-8">
            <div className="sm:justify-between sm:items-center md:flex">
              <div className="text-center sm:text-left">
                <div className="flex flex-col lg:justify-between lg:flex-row">
                  <h2 className="max-w-lg font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group">
                    <span className="inline-block">
                      Ticket Holder Information
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-8">
          <div className="mb-2 border-dashed hover:border-gray-400 border-2 relative p-5 bg-white rounded-sm  w-full">
            <div className="pr-4 grid md:flex-row md:flex-wrap  lg:flex-nowrap gap-8">
              {/* {tickets?.ticket_id && tickets?.is_paid && (
              <div className=" max-w-lg mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                <QRCode size={150} value={tickets.ticket_id} />
              </div>
            )} */}
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      full name
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.full_name || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Contact Number
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.contact_number || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Church Name
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.church_name || "-"}
                      {tickets?.others_church_name
                        ? ", " + tickets?.others_church_name
                        : ""}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Location
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.city || "-"}
                      {tickets?.province ? ", " + tickets?.province : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <header className="">
          <div className="max-w-screen-xl mx-auto sm:pb-8">
            <div className="sm:justify-between sm:items-center md:flex">
              <div className="text-center sm:text-left">
                <div className="flex flex-col lg:justify-between lg:flex-row">
                  <h2 className="max-w-lg font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group">
                    <span className="inline-block">Ticket List:</span>
                  </h2>
                </div>
              </div>

              {tickets?.is_paid && (
                <div className="flex flex-col gap-4 mt-4 sm:flex-row sm:mt-0 sm:items-center">
                  <NavLink
                    to={"/view-pdf/" + tickets.ticket_id}
                    className="block px-5 py-3 text-sm font-medium text-blue-accent-400 transition rounded-lg border-2 border-transparent hover:border-2 hover:border-blue-accent-400 focus:outline-none focus:ring"
                  >
                    View/Download Tickets as PDF
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </header>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8">
          {Array.from({ length: tickets.no_of_tickets }, (_, index) => (
            <div
              className="mb-2 border-dashed hover:border-gray-400 border-2 relative p-5 bg-white rounded-sm  w-full"
              key={index}
            >
              <div className="pr-4 grid md:flex-row md:flex-wrap lg:flex-nowrap gap-8">
                <>
                  <div className=" max-w-lg grid justify-center mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                    <QRCode
                      size={150}
                      value={
                        tickets.ticket_id +
                        "-" +
                        (index + 1).toString().padStart(3, "000")
                      }
                    />
                  </div>
                  <div>
                    <div className="grid">
                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md text-center">
                        Ticket ID
                      </p>
                      <p className="font-sans font-semibold text-gray-900 lg:text-xl lg:max-w-md text-center">
                        {tickets.ticket_id +
                          "-" +
                          (index + 1).toString().padStart(3, "000")}
                      </p>
                    </div>
                  </div>
                </>
              </div>
            </div>
          ))}
        </div>
        <Dialog
          open={openUploadDialog}
          onClose={() => progresspercent !== 0 && setOpenUploadDialog(false)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
              <span className="inline-block mb-1 sm:mb-4">
                {tickets?.transaction_img_src ? "View and" : ""} Appprove
                Transaction
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              component={"div"}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {!tickets?.transaction_img_src && (
                <>
                  <Alert severity="info">
                    <span className="font-sans">
                      <b>Note:</b> User hasn't submitted any transaction
                      receipt.
                    </span>
                  </Alert>
                  <section className="container mt-4"></section>
                </>
              )}
              {tickets?.transaction_img_src && (
                <div className="p-8 bg-gray-200 mb-4">
                  <img
                    src={tickets?.transaction_img_src}
                    className="w-full"
                    alt="uploaded transaction"
                  />
                  {tickets?.user_message && (
                    <>
                      <p className="mb-1 text-xs mt-4 font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                        User Provided Remarks:
                      </p>
                      <p className="mb-1 text-md mt-4 font-sans md:text-start text-black font-semibold tracking-wide">
                        {tickets?.user_message}
                      </p>
                    </>
                  )}
                </div>
              )}
              <p className="mb-4 mt-8 text-xs font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                Transaction Details:
              </p>
              <div className="mb-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Transaction Reference No."
                  placeholder="Reference no from the bank"
                  fullWidth
                  multiline
                  value={refNo}
                  onChange={(ev) => setRefNo(ev.currentTarget.value)}
                />
              </div>
              <div className="mb-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Transaction mode of payment"
                  placeholder="(On site payment / Bank Transfer via...)"
                  fullWidth
                  multiline
                  value={modeOfPayment}
                  onChange={(ev) => setModeOfPayment(ev.currentTarget.value)}
                />
              </div>
              <div className="mb-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Additional Information / Remarks"
                  fullWidth
                  multiline
                  value={adminRemarks}
                  onChange={(ev) => setAdminRemarks(ev.currentTarget.value)}
                  rows={4}
                />
              </div>
              <div className="mb-4">
                <TextField
                  id="outlined-multiline-static"
                  label="Approved By"
                  fullWidth
                  value={approvedBy}
                  onChange={(ev) => setApprovedBy(ev.currentTarget.value)}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <span
              tabIndex={0}
              className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
              onClick={() => setOpenUploadDialog(false)}
            >
              Back
            </span>
            {!tickets?.transaction_approved && (
              <span
                tabIndex={0}
                className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-blue-accent-400 text-white bg-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl`}
                onClick={() => {
                  handleApproveRequest(
                    refNo,
                    modeOfPayment,
                    adminRemarks,
                    approvedBy
                  );
                }}
              >
                {submitText}
              </span>
            )}
          </DialogActions>

          {!!progresspercent && (
            <div
              style={{
                display: "grid",
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "white",
                zIndex: "99",
                opacity: "0.9",
              }}
            >
              {" "}
              <div className="block py-4 m-auto">
                <span className="font-sans font-semibold uppercase">
                  {uploadStatus}
                </span>
                <div className="w-full h-4 bg-gray-400 rounded-full mt-3">
                  <div
                    style={{ width: progresspercent + "%" }}
                    className="h-full text-center text-xs text-white bg-green-500 rounded-full"
                  >
                    {progresspercent}%
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dialog>
        <Dialog
          open={openPaymentGuideline}
          onClose={() => setOpenPaymentGuideline(false)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
              <span className="inline-block mb-1 sm:mb-4">
                Payment Guideline
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              component={"div"}
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <PaymentGuide hideImage />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <span
              tabIndex={0}
              className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
              onClick={() => setOpenPaymentGuideline(false)}
            >
              Back
            </span>
          </DialogActions>

          {!!progresspercent && (
            <div
              style={{
                display: "grid",
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "white",
                zIndex: "99",
                opacity: "0.9",
              }}
            >
              {" "}
              <div className="block py-4 m-auto">
                <span className="font-sans font-semibold uppercase">
                  {uploadStatus}
                </span>
                <div className="w-full h-4 bg-gray-400 rounded-full mt-3">
                  <div
                    style={{ width: progresspercent + "%" }}
                    className="h-full text-center text-xs text-white bg-green-500 rounded-full"
                  >
                    {progresspercent}%
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </>
  );
}
