import { Alert, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, Grow, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { cityList } from "./cityList";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TransitionGroup } from 'react-transition-group'
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { UserAuth } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const initVal = {
  full_name: '',
  contact_number: '',
  age_group: '',
  church_name: '',
  others_church_name: '',
  city: '',
  province: '',
  uuid: uuid()
}

const initialValues = {
  tickets: [initVal],
};

export default function RegistrationForm() {
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openReviewSummary, setOpenReviewSummary] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0);
  const descriptionElementRef = React.useRef(null);
  const navigate = useNavigate();
  const { addTicket, showWarnToast, showToast } = UserAuth();

  React.useEffect(() => {
    if (openReviewSummary) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openReviewSummary]);


  const validationSchema = yup.object().shape({
    tickets: yup.array()
      .of(yup.object().shape({
        full_name: yup.string().required('Full Name is required'),
        church_name: yup.string().required('Church Name is required'),
        contact_number: yup.string().required('Contact Number is required'),
        city: yup.string().required('City is required'),
        province: yup.string().required('Province is required'),
      }))
  })

  // const initialIsValid = validationSchema.isValidSync(initialValues)

  let earlyBirdDate = new Date("09/13/2022")
  let diff = new Date().getTime() - earlyBirdDate;
  let isEarlyBird = true;
  if (diff >= 0) {
    isEarlyBird = false;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      isValid={false}
      onSubmit={async (values) => {
        await addTicket(values);
        setOpenReviewSummary(false);
        showToast("Ticket successfully created");
        navigate('/my-tickets')
      }}
    >
      {({ isSubmitting, isValidating, validateForm, initialErrors, isValid, errors, handleChange, resetForm, submitForm, handleBlur, setFieldValue, values }) => (
        <>
          <Form>
            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
              <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
                <div className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                  <span className="inline-block mb-1 sm:mb-4">
                    Reserve Ticket
                  </span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
                </div>
                <p className="text-gray-700 lg:text-sm lg:max-w-md">
                  Notice: Only seven (7) days are reserved for your registration. All unpaid registrations will be deleted from your profile if the payment is not done in due time. Please make payment within the allotted time.
                </p>
              </div>
              <Box display="grid" className="grid grid-cols-1 xl:grid-cols-2 bg-white rounded md:justify-center lg:flex-row flex-wrap">
                <TransitionGroup component={null}>
                  {values.tickets.length > 0 &&
                    values.tickets.map((ticket, index) => (
                      <Grow
                        key={ticket.uuid}
                        in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...({ timeout: 500 })}
                      >
                        <div className="flex flex-col justify-between p-5 sm:p-10 border border-gray-300">
                          <div>
                            <div className="flex justify-between align-start">
                              <div>
                                <Tooltip title="Early bird promo is only applicable for payment submitted before September 12" placement="top">
                                  <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
                                    {isEarlyBird && <><span style={{ textDecoration: "line-through", opacity: "0.5" }}>Php 300.00</span> Php 200.00</>}
                                    {!isEarlyBird && <span>Php 300.00</span>}
                                  </p>
                                </Tooltip>
                                <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
                                  Ticket #{index + 1}
                                </h5>
                              </div>
                              {index >= 1 && <div>
                                <Tooltip title="Click to remove this ticket"
                                >
                                  <HighlightOffIcon style={{ cursor: "pointer" }} onClick={() => {
                                    setSelectedTicketIndex(index);
                                    setOpenDeleteConfirmDialog(true)
                                  }} />
                                </Tooltip>
                              </div>}
                            </div>

                            <div>
                              <Box display='grid' gap="20px">
                                <TextField id="outlined-basic" label="Full Name"
                                  error={!!(errors?.tickets && errors.tickets[index]?.full_name)}
                                  helperText={errors?.tickets && errors.tickets[index]?.full_name}
                                  value={ticket?.full_name} onChange={handleChange} name={`tickets.${index}.full_name`} variant="outlined" fullWidth />
                                <Grid container wrap="nowrap" gap="20px">
                                  <TextField id="outlined-basic" label="Contact Number" type="tel"
                                    error={!!(errors?.tickets && errors.tickets[index]?.contact_number)}
                                    helperText={errors?.tickets && errors.tickets[index]?.contact_number}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">(+63)</InputAdornment>,
                                    }} value={ticket?.contact_number} onChange={handleChange} name={`tickets.${index}.contact_number`} variant="outlined" fullWidth />
                                  <FormControl fullWidth>
                                    <InputLabel id={"select-age-group-label-" + index}>Age Group</InputLabel>
                                    <Select
                                      labelId={"select-age-group-label-" + index}
                                      id={"select-age-group-" + index}
                                      label="Age Group"
                                      name={`tickets.${index}.age_group`}
                                      value={ticket ? ticket.age_group : ""}
                                      onChange={handleChange}
                                    >
                                      <MenuItem disabled value={""}>Select Age Group</MenuItem>
                                      <MenuItem value={"Youth"}>0-17yrs old</MenuItem>
                                      <MenuItem value={"Young Adults"}>18-29yrs old</MenuItem>
                                      <MenuItem value={"Adults"}>30+yrs old</MenuItem>
                                    </Select>

                                  </FormControl>
                                </Grid>

                                <FormControl fullWidth>
                                  <InputLabel
                                    error={!!(errors?.tickets && errors.tickets[index]?.church_name)}
                                    id={"select-church-name-label-" + index} >Church Name</InputLabel>
                                  <Select
                                    labelId={"select-church-name-label-" + index}
                                    id={"select-church-name-select-" + index}
                                    label="Church Name"
                                    name={`tickets.${index}.church_name`}
                                    value={ticket ? ticket.church_name : ""}
                                    onChange={handleChange}
                                    error={!!(errors?.tickets && errors.tickets[index]?.church_name)}

                                  >
                                    <MenuItem disabled value={""}>CROSSWorld Churces</MenuItem>
                                    <MenuItem value='CROSSWorld Central'>CROSSWorld Central</MenuItem>
                                    <MenuItem value='CROSSWorld Kalookan'>CROSSWorld Kalookan</MenuItem>
                                    <MenuItem value='CROSSWorld Obando'>CROSSWorld Obando</MenuItem>
                                    <MenuItem value='CROSSWorld Valenzuela'>CROSSWorld Valenzuela</MenuItem>
                                    <MenuItem value='CROSSWorld San Rafael'>CROSSWorld San Rafael</MenuItem>
                                    <MenuItem value='CROSSWorld Marilao'>CROSSWorld Marilao</MenuItem>
                                    <MenuItem value='CROSSWorld Jesus Castle'>CROSSWorld Jesus Castle</MenuItem>
                                    <MenuItem value='CROSSWorld Bignay'>CROSSWorld Bignay</MenuItem>
                                    <MenuItem value='CROSSWorld Nueva Ecija'>CROSSWorld Nueva Ecija</MenuItem>
                                    <MenuItem value='CROSSWorld Sibug'>CROSSWorld Sibug</MenuItem>
                                    <MenuItem value='CROSSWorld Sta. Barbara'>CROSSWorld Sta. Barbara</MenuItem>
                                    <MenuItem value='CROSSWorld San Carlos'>CROSSWorld San Carlos</MenuItem>
                                    <MenuItem value='CROSSWorld San Vicente'>CROSSWorld San Vicente</MenuItem>
                                    <MenuItem value='CROSSWorld Davao'>CROSSWorld Davao</MenuItem>
                                    <MenuItem value='CROSSWorld Bacolod'>CROSSWorld Bacolod</MenuItem>
                                    <MenuItem value='CROSSWorld Peñafrancia'>CROSSWorld Peñafrancia</MenuItem>
                                    <MenuItem value='CROSSWorld Burias'>CROSSWorld Burias</MenuItem>
                                    <MenuItem value='CROSSWorld UK'>CROSSWorld UK</MenuItem>
                                    <MenuItem value='CROSSWorld Indonesia'>CROSSWorld Indonesia</MenuItem>
                                    <MenuItem value='CROSSWorld Ubihan'>CROSSWorld Ubihan</MenuItem>
                                    <MenuItem value='CROSSWorld Meycauayan East'>CROSSWorld Meycauayan East</MenuItem>
                                    <MenuItem value='CROSSWorld Tiaong'>CROSSWorld Tiaong</MenuItem>
                                    <MenuItem value='Others'>Others</MenuItem>
                                  </Select>

                                  {errors?.tickets && errors.tickets[index]?.church_name && (
                                    <FormHelperText htmlFor="form-selector" error={!!(errors?.tickets && errors.tickets[index]?.church_name)}>
                                      {errors?.tickets && errors.tickets[index]?.church_name}
                                    </FormHelperText>
                                  )}
                                </FormControl>

                                {values.tickets[index].church_name === "Others" && <TextField disabled={values.tickets[index].church_name !== "Others"}
                                  value={ticket?.others_church_name}
                                  onChange={handleChange} id="outlined-basic" label="Other Church, Please Specify" variant="outlined" name={`tickets.${index}.others_church_name`} fullWidth />
                                }
                                <Grid container wrap="nowrap" gap="20px">
                                  <TextField
                                    value={ticket?.city}
                                    onChange={handleChange} id="outlined-basic"
                                    error={!!(errors?.tickets && errors.tickets[index]?.city)}
                                    helperText={errors?.tickets && errors.tickets[index]?.city} label="City" variant="outlined" name={`tickets.${index}.city`} fullWidth />
                                  <FormControl fullWidth>
                                    <InputLabel
                                      error={!!(errors?.tickets && errors.tickets[index]?.province)}
                                      id={"select-province-label-" + index}>Province</InputLabel>
                                    <Select
                                      labelId={"select-province-label-" + index}
                                      id="select-province-select"
                                      label="Province"
                                      name={`tickets.${index}.province`}
                                      value={ticket ? ticket.province : ""}
                                      onChange={handleChange}
                                      error={!!(errors?.tickets && errors.tickets[index]?.province)}
                                    >
                                      <MenuItem disabled value={""}>Select Province</MenuItem>
                                      {cityList.map((city, i) => <MenuItem key={i} value={city}>{city}</MenuItem>)}
                                    </Select>

                                    {errors?.tickets && errors.tickets[index]?.province && (
                                      <FormHelperText htmlFor="form-selector" error={!!(errors?.tickets && errors.tickets[index]?.province)}>
                                        {errors?.tickets && errors.tickets[index]?.province}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Grow>
                    ))}

                  <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0' }}
                    {...({ timeout: 500 })}
                  >
                    <div className="flex flex-col justify-between p-5 sm:p-10 border border-gray-300 bg-gray-300">
                      <div>
                        <p className="mb-2 text-xs font-semibold tracking-wide uppercase">

                        </p>
                        <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
                          Need More Ticket?
                        </h5>
                        <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
                          Click the Add Ticket button to add new ticket under this order.
                        </p>
                      </div>
                      <div className="flex items-center">
                        <span
                          tabIndex={0}
                          className="cursor-pointer inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-blue-accent-400 transition duration-200 rounded-full shadow-md border border-blue-accent-400 hover:border-blue-accent-700 text-blue-accent-400 focus:shadow-outline focus:outline-none hover:shadow-xl"
                          onClick={() => setFieldValue(`tickets`, values.tickets?.concat({ ...initVal, uuid: uuid(), church_name: values?.tickets[0]?.church_name || '', city: values?.tickets[0]?.city || '', province: values?.tickets[0]?.province || '' }))}
                        >
                          + Add More Ticket
                        </span>
                      </div>
                    </div>
                  </Grow>
                </TransitionGroup>
              </Box>

              <div className="grid justify-between items-center mt-10 grid-cols-1 md:grid-cols-2">
                <div>
                  <p className="mb-2 text-xs text-end md:text-start font-semibold tracking-wide uppercase">
                    Total Amount to pay:
                  </p>
                  <h5 className="text-end md:text-start text-3xl font-extrabold my-0 leading-none sm:text-2xl">
                    {isEarlyBird && <span style={{ textDecoration: "line-through", opacity: "0.5" }}>Php {values.tickets.length * 300} </span>}Php {values.tickets.length * (isEarlyBird ? 200 : 300)}.00
                  </h5>
                  {isEarlyBird && <p className="text-sm text-gray-700">Early bird promo is only applicable for payment submitted before September 12</p>}
                </div>
                <div className="flex justify-end items-end gap-5 mt-8 md:mt-0">
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => setShowResetDialog(true)}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md border border-blue-accent-400 hover:border-blue-accent-700 text-blue-accent-400 focus:shadow-outline focus:outline-none hover:shadow-xl"
                  >
                    Clear all fields
                  </span>
                  <span
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      validateForm().then(() => {
                        const tix = values?.tickets || []
                        const validFormCount = tix.reduce((prev, { full_name, contact_number, church_name, city, province }) => prev += (((full_name ? 1 : 0) + (contact_number ? 1 : 0) + (church_name ? 1 : 0) + (province ? 1 : 0) + (city ? 1 : 0))), 0)
                        if (validFormCount && validFormCount >= (tix.length * 5)) {
                          setOpenReviewSummary(true)
                        } else {
                          showWarnToast("Some fields are required.")
                        }
                      })
                    }}
                    className={`bg-blue-accent-400 inline-flex items-center  justify-center h-12 px-6 font-medium tracking-wide transition duration-200 rounded shadow-md border border-blue-accent-400 hover:border-blue-accent-700 text-white focus:shadow-outline focus:outline-none hover:shadow-xl`}
                  >
                    Submit Form
                  </span>
                </div>
              </div>
            </div>

            <Dialog
              open={openDeleteConfirmDialog}
              onClose={() => { setOpenDeleteConfirmDialog(false) }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                  <span className="inline-block mb-1 sm:mb-4">
                    {"Remove Ticket #" + (selectedTicketIndex + 1)}
                  </span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText component={'div'} id="alert-dialog-description">
                  <p className="font-sans text-gray-700 lg:text-sm lg:max-w-md">
                    Are you sure you want to remove this ticket from this order?
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none"
                  onClick={() => { setOpenDeleteConfirmDialog(false) }}>
                  Cancel
                </span>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-blue-accent-400 text-white bg-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl"
                  onClick={() => {
                    const tix = values.tickets;
                    tix.splice(selectedTicketIndex, 1);
                    setFieldValue(`tickets`, tix)
                    setOpenDeleteConfirmDialog(false)
                  }}>
                  Remove
                </span>
              </DialogActions>
            </Dialog>

            <Dialog
              open={showResetDialog}
              onClose={() => { setShowResetDialog(false) }}
              aria-labelledby="alert-dialog-title-reset"
              aria-describedby="alert-dialog-description-reset"
            >
              <DialogTitle id="alert-dialog-title-reset">
                <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                  <span className="inline-block mb-1 sm:mb-4">
                    Reset Form
                  </span>
                  <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText component={'div'} id="alert-dialog-description-reset">
                  <p className="font-sans text-gray-700 lg:text-sm lg:max-w-md">
                    Are you sure you want to clear all the fields?
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none"
                  onClick={() => { setShowResetDialog(false); }}>
                  Cancel
                </span>
                <span
                  tabIndex={0}
                  className="cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-blue-accent-400 text-white bg-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl"
                  onClick={() => {
                    setShowResetDialog(false)
                    resetForm()
                  }}>
                  Remove
                </span>
              </DialogActions>
            </Dialog>
          </Form>
          <Dialog
            open={openReviewSummary}
            onClose={() => !isSubmitting && setOpenReviewSummary(false)}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"

          >
            <DialogTitle id="scroll-dialog-title">
              <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                  Ticket Order Summary
                </span>
                <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
              </div>
            </DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                component={'div'}
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <div>
                  <p className="mb-1 mt-1 text-xs font-sans md:text-start text-black font-semibold tracking-wide uppercase">
                    Total Amount to pay:
                  </p>
                  <h5 className="text-end md:text-start font-sans text-black text-3xl font-extrabold my-0 leading-none sm:text-xl">
                    {isEarlyBird && <span style={{ textDecoration: "line-through", opacity: "0.5" }}>Php {values.tickets.length * 300} </span>}Php {values.tickets.length * (isEarlyBird ? 200 : 300)}.00
                  </h5>
                  {isEarlyBird && <p className="text-sm text-gray-700 font-sans  mb-4">Early bird promo is only applicable for payment submitted before September 12</p>}
                </div>
                <Alert severity="info"><span className="font-sans"><b>Note:</b> Payment will be done via bank transfer. After submitting the form, please send the payment to our bank account and send us the copy of the receipt. Detailed instruction will follow after you submit the form.<br /><br />Also, please make sure that the information you provided is correct as you can no longer able to edit it once submitted.<br /><br /> Below are the summary of the tickets:</span></Alert>
                <div
                  className="relative block  mt-4 p-px overflow-hidden transition duration-300 transform"
                >
                  {values?.tickets?.map((tix, index) => (
                    <div key={index} className="mb-2 border-dashed hover:border-gray-400 rounded border-2 relative p-5 bg-white rounded-sm  w-full">
                      <div className="pr-4">
                        <div className="max-w-lg mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                          <span className="inline-block mb-1 sm:mb-2">
                            Ticket #{index + 1}
                          </span>
                          <div className="h-0.5 mb-2 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                          <div className="grid mt-2">
                            <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                              full name
                            </p>
                            <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                              {tix?.full_name || "-"}
                            </p>
                          </div>
                          <div className="grid mt-2">
                            <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                              Age Group
                            </p>
                            <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                              {tix?.age_group || "-"}
                            </p>
                          </div>
                          <div className="grid mt-2">
                            <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                              Contact
                            </p>
                            <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                              {tix?.contact_number || "-"}
                            </p>
                          </div>
                          <div className="grid mt-2">
                            <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                              Church Name
                            </p>
                            <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                              {tix?.church_name || "-"}
                              {tix?.others_church_name ? ", " + tix?.others_church_name : ""}
                            </p>
                          </div>
                          <div className="grid mt-2">
                            <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                              Location
                            </p>
                            <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                              {tix?.city || "-"}
                              {tix?.province ? ", " + tix?.province : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <span
                tabIndex={0}
                className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none${isSubmitting ? ' bg-gray-200 border-none text-gray-700 opacity-75' : ''}`}
                onClick={() => !isSubmitting && setOpenReviewSummary(false)}>
                Back to Form
              </span>
              <span
                tabIndex={0}
                className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md shadow-md border border-blue-accent-400 text-white bg-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none hover:shadow-xl${isSubmitting ? ' bg-gray-200 border-none text-gray-700' : ''}`}
                onClick={() => {
                  !isSubmitting && submitForm()
                }}>
                {!isSubmitting ? "Submit" : "Submitting..."}
              </span>
            </DialogActions>
          </Dialog>
        </>
      )
      }
    </Formik >
  );
}
