import React, { useState } from "react";
import { UserAuth } from "../../context/AppContext";
import ReactLoading from 'react-loading';
import GoogleButton from "react-google-button";
import { Alert, CircularProgress, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { Outlet } from "react-router-dom";

export default function ProtectedPage({ children, title, subtitle, showButton = true }) {
  const { user, handleUserLogin } = UserAuth();

  const [authError, setAuthError] = useState('')

  const validationSchema = yup.object().shape({
    email: yup.string().email("Please enter valid email").required('Email is required'),
    password: yup.string().required('Password is required'),
  })

  if (user === null) {
    return <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="flex flex-col items-center mb-16 sm:text-center sm:mb-0">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              {"You are not authorized to view this page"}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {"You need to login first before you can view this page."}
            </p>
          </div>
          {showButton &&
            <>
              {user === 'init' && <CircularProgress />}
              {user !== 'init' && !user?.email && <>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    await handleUserLogin(values.email, values.password)
                      .then((userCredential) => {
                        console.log(userCredential)
                      })
                      .catch((error) => {
                        const errorMessage = error.message;
                        setAuthError(errorMessage)
                      });
                  }}
                >
                  {({ isSubmitting, touched, validateForm, errors, handleChange, submitForm, values }) => (
                    <>
                      <Form>
                        {console.log(errors)}
                        <section className="shadow-2xl rounded-3xl">
                          <div className="p-8 text-center sm:p-12">
                            <p className="text-sm font-semibold tracking-widest text-blue-accent-400 uppercase mb-4">Enter your login credentials</p>
                            {authError && <Alert severity="warning">{authError}</Alert>}
                            <TextField
                              name="email"
                              type="email"
                              placeholder="johndoe@email.com"
                              label="Email"
                              value={values.email}
                              onChange={handleChange}
                              fullWidth
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              style={{
                                marginBottom: '12px',
                              }}
                            />
                            <TextField
                              name="password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder="password"
                              fullWidth
                              label="Password"
                              error={touched.password && Boolean(errors.password)}
                              helperText={touched.password && errors.password}
                            />

                            <span onClick={() => {
                              if (!isSubmitting) {
                                submitForm();
                              }
                            }} className="cursor-pointer inline-block w-full py-4 mt-8 text-sm font-bold text-white bg-pink-600 rounded-full shadow-xl">
                              {isSubmitting ? "Logging in..." : "Log in"}
                            </span>
                          </div>
                        </section>
                      </Form>
                    </>
                  )}
                </Formik>
              </>}
            </>
          }
        </div>
      </div>
    </div>
  } if (user === 'init') {
    return <div className="grid justify-center items-center h-96"><ReactLoading type="bubbles" color="#313192" height={150} width={150} /></div>
  }
  if (user?.email) {
    return <Outlet />
  }

  return children
}
