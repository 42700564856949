export const getMessageByStatusCode = (code) => {
  if (code === "101") {
    return {
      title: `User submitted the receipt, please review and approve`,
      subtitle: ``
    }
  }
  else if (code === "102") {
    return {
      title: `Ticket successfully validated`,
      subtitle: ``
    }
  }
  return {
    title: `User hasn't upload any transaction receipt yet`,
    subtitle: ``
  }
}