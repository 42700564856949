import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { toast } from "react-toastify";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  setDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { padStart } from "lodash";

const AppContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("init");
  const [accountTickets, setAccountTickets] = useState("init");
  const [searchValue, setSearchValue] = useState("");

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const logout = () => {
    signOut(auth);
  };

  const showToast = (message) => {
    toast.success(message);
  };

  const showErrorToast = (message) => {
    toast.error(message);
  };
  const showWarnToast = (message) => {
    toast.warn(message);
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (err) {
      console.log(err);
    }
  };
  const handleUserLogin = (email, pass) => {
    return signInWithEmailAndPassword(auth, email, pass);
  };

  const logoutUser = async () => {
    try {
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  const addTicket = async (ticketData) => {
    let earlyBirdDate = new Date("09/13/2022");
    let diff = new Date().getTime() - earlyBirdDate;
    let isEarlyBird = true;
    if (diff >= 0) {
      isEarlyBird = false;
    }
    const ticket_id = uuid().substring(0, 6).toUpperCase();
    const amount = isEarlyBird ? 200 : 300;
    const sampleRequest = {
      tickets: ticketData.tickets.map((tix, i) => ({
        ...tix,
        amount: amount,
        ticket_id: ticket_id + "-" + padStart(i + 1 + "", 3, "0"),
      })),
      account_id: user?.uid,
      email: user?.email,
      ticket_id: ticket_id,
      dateAdded: new Date(),
      status: "Pending Payment",
      status_code: "100",
      status_message: "",
      transaction_img_src: "",
      user_message: "",
    };

    const tix = doc(collection(db, "tickets"));
    await setDoc(tix, sampleRequest);
  };

  const updateTicketTransaction = async (ticketData, imgUrl, remarks) => {
    const request = {
      status_message: "Your uploaded receipt is under approval",
      status: "Pending Approval",
      status_code: "101",
      transaction_img_src: imgUrl,
      date_payment_submitted: new Date(),
      user_message: remarks,
    };

    const ticketRef = doc(db, "tickets", ticketData.doc_id);
    await setDoc(ticketRef, { ...request }, { merge: true });
  };

  const updateApproveStatus = async (
    ticketData,
    { refNo, modeOfPayment, adminRemarks, approvedBy }
  ) => {
    const request = {
      status: "Paid",
      status_code: "102",
      is_paid: true,
      date_approved: new Date(),
      transaction_ref_no: refNo,
      transaction_mode: modeOfPayment,
      transaction_remarks: adminRemarks,
      transaction_approver: approvedBy,
      transaction_approver_email: user?.email,
    };

    const ticketRef = doc(db, "tickets", ticketData.doc_id);
    await setDoc(ticketRef, { ...request }, { merge: true });
  };

  const updateTicketDayAttendance = async (
    ticketData,
    { ticketId, day, isDeletion }
  ) => {
    // Initialize attendance if not defined
    if (!ticketData.attendance) {
      ticketData.attendance = {};
    }

    ticketData.attendance[
      `${ticketId}-day_${day === 1 ? "one" : "two"}_attendance`
    ] = isDeletion
      ? false
      : {
          approvedBy: user?.email,
          dateScanned: new Date(),
        };

    const updatedTicket = ticketData;

    const ticketRef = doc(db, "tickets", ticketData.doc_id);
    await setDoc(ticketRef, { ...updatedTicket }, { merge: true });
  };

  useEffect(() => {
    let firestoreUnsubscribe = null;
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser?.uid) {
        const q = query(collection(db, "tickets"));
        firestoreUnsubscribe = onSnapshot(q, (querySnapshot) => {
          const ticket = [];
          querySnapshot.forEach((doc) => {
            ticket.push({ ...doc.data(), doc_id: doc.id });
          });
          console.log(ticket);
          setAccountTickets(
            ticket
              .sort((a, b) => a.dateAdded.seconds - b.dateAdded.seconds)
              .filter((tix) => tix.status_code !== "500")
          );
        });
      }
      if (user === null) {
        firestoreUnsubscribe && firestoreUnsubscribe();
      }
    });
    return () => {
      unsubscribe();
      firestoreUnsubscribe && firestoreUnsubscribe();
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        addTicket,
        searchValue,
        setSearchValue,
        updateTicketDayAttendance,
        updateTicketTransaction,
        updateApproveStatus,
        handleGoogleSignIn,
        showWarnToast,
        user,
        accountTickets,
        handleUserLogin,
        logoutUser,
        showToast,
        showErrorToast,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AppContext);
};
