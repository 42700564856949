const GuideItem = ({ title, children }) => {
  return <div className="flex">
    <div className="flex flex-col items-center mr-4">
      <div>
        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
          <svg
            className="w-4 text-gray-600"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <line
              fill="none"
              strokeMiterlimit="10"
              x1="12"
              y1="2"
              x2="12"
              y2="22"
            />
            <polyline
              fill="none"
              strokeMiterlimit="10"
              points="19,15 12,22 5,15"
            />
          </svg>
        </div>
      </div>
      <div className="w-px h-full bg-gray-300" />
    </div>
    <div className="pt-1 pb-8">
      <p className="mb-2 text-lg font-bold font-sans">{title}</p>
      <p className="text-gray-700 font-sans">
        {children}
      </p>
    </div>
  </div>
}

export default function Guide() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <h2 className="mb-6 font-sans text-2xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        How to register?
      </h2>
      <div className="grid gap-6 row-gap-10 lg:grid-cols-2">

        <div className="lg:py-6 lg:pr-16">
          <GuideItem title="Step 1">Click the “REGISTER” button and log in using a Google account.</GuideItem>
          <GuideItem title="Step 2">After successfully logging in, click “REGISTER” again and fill out the information for
            each ticket. You can register more than one ticket using 1 Google account, just click “add more
            tickets”</GuideItem>
          <GuideItem title="Step 3">Click “SUBMIT FORM”. Check if all the information, amount, and number of tickets you
            want to purchase are correct.</GuideItem>
          <GuideItem title="Step 4">After successfully registering, kindly follow the instruction on “PAYMENT GUIDELINES”
            on how to pay for your registered ticket(s) and submit your proof of payment.</GuideItem>
          <GuideItem title="Step 5"> Go back to the website and click “MY TICKETS” on your profile to check the status of
            your registration. You will be able to access, view, and download your ticket/s on the website
            once your status is “APPROVED”.</GuideItem>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-6 text-gray-600"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <polyline
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      points="6,12 10,16 18,8"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="pt-1">
              <p className="mb-2 text-lg font-bold font-sans">Success!</p>
              <p className="text-gray-700" />
            </div>
          </div>
        </div>
        <div className="relative">
          <img
            className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
            src="/images/registration.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export function PaymentGuide({ hideImage }) {
  return (
    <div className={`${hideImage ? '' : 'px-4 py-16  md:px-24 lg:px-8 lg:py-20 '}mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl`}>
      <h2 className="mb-6 font-sans text-2xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        How to send the payment?
      </h2>
      <p className="text-md text-start text-gray-900">
        Payment can be done via Bank transfer or Bank deposit, within seven (7) days from the
        registration date.
      </p>
      <div className={`grid gap-6 row-gap-10`}>
        <div className="lg:py-6 lg:pr-16">
          <GuideItem title="Step 1:"><b>Via Bank Transfer:</b><br /><br /><b>Bank Name:</b> BDO<br /><b>Account Name:</b> CHRIST ENTHRONED CHURCH INC<br /><b>Account Number:</b> 1040244295<br /><br /><b>Via GCash:</b><br />On the Gcash App, click on Bank Transfer, and select BDO. Kindly refer to the
            information above for the details of the account.</GuideItem>
          <GuideItem title="Step 2">Take a photo or download the payment receipt, then go to the CROSSWorld IDMC
            website. Click on your profile and go to "My ticket"</GuideItem>
          <GuideItem title="Step 3">Click the "Order ticket ID" of the ticket you paid for and click "upload receipt"</GuideItem>
          <GuideItem title="Step 4">Upload the photo of the proof of payment and click “upload image and submit”.</GuideItem>
          <GuideItem title="Step 5">Your ticket status will be “Pending Approval”. Please allow three to five working days to confirm
            your payment.</GuideItem>
          <GuideItem title="Step 6">Once approved, your ticket status will be “Approved” and you will be able to access,
            view, and download your ticket/s on the website. If you have any question, kindly contact us via idmccrossworld@gmail.com</GuideItem>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-6 text-gray-600"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <polyline
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      points="6,12 10,16 18,8"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="pt-1">
              <p className="mb-2 text-lg font-bold font-sans">Thank you and God bless!</p>
              <p className="text-gray-700 font-sans" >Bring and present your conference ticket to the registration booth on day 1 and day 2 of
                the conference. A “No conference ticket or QR code, No Entry” policy will be observed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};