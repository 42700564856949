import React from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserAuth } from "../../context/AppContext";
import {
  Page as PDFPage,
  Text,
  Document as PDFDocument,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import dataUriToBuffer from "data-uri-to-buffer";
import Scanner from "react-webcam-qr-scanner";
import NotFound from "../../components/NotFound/NotFound";
import { useState } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PaymentGuide } from "../../components/Guide/Guide";
import { CheckCircle } from "@mui/icons-material";
import { padStart } from "lodash";

export default function ScanCode() {
  const { accountTickets } = UserAuth();

  const tixId =
    accountTickets !== "init" && accountTickets?.map((e) => e.ticket_id);
  const navigate = useNavigate();

  const handleDecode = ({ data }) => {
    if (
      data.length === 10 &&
      data.charAt(6) === "-" &&
      tixId.includes(data.split("-")[0])
    ) {
      navigate("/scan-code/" + data);
    }
  };

  const handleScannerLoad = (mode) => {
    console.log(mode);
  };

  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <h2 className="font-sans text-center text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group mb-12">
          <span className="inline-block">Ticket QR Code Scanner:</span>
        </h2>
        <Scanner
          className="mx-auto"
          onDecode={handleDecode}
          onScannerLoad={handleScannerLoad}
          constraints={{
            audio: false,
            video: {
              facingMode: "environment",
            },
          }}
          captureSize={{ width: 1280, height: 720 }}
        />
      </div>
    </>
  );
}
export function ScanCodeResult() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { accountTickets, updateTicketDayAttendance, showToast } = UserAuth();
  const [confirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDialogData, setOpenConfirmDialogData] = useState({});
  const [submitText, setSubmitText] = useState("Confirm");

  const tickets =
    (accountTickets !== "init" &&
      accountTickets.find((ticket) => ticket.ticket_id === id.split("-")[0])) ||
    [];
  const scannedTix = tickets || false;

  if (tickets.length === 0) {
    return <NotFound />;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOpenConfirmDialog = (day) => {
    setOpenConfirmDialogData({
      day: day,
      dayString: day === 1 ? "One" : "Two",
      isDeletion: scannedTix[`day_${day === 1 ? "one" : "two"}_attendance`]
        ? true
        : false,
    });
    setOpenConfirmDialog(true);
  };
  const handleSubmit = async (day) => {
    setSubmitText("Submitting...");
    await updateTicketDayAttendance(tickets, {
      ticketId: id,
      day: day,
      isDeletion: scannedTix?.attendance?.[getDayKey(~~id?.split("-")[1], day)]
        ? true
        : false,
    });
    showToast("Successfully updated ticket attendance");
    setOpenConfirmDialog(false);
    setSubmitText("Confirm");
  };

  const getDayKey = (index, key) => {
    const keyReturn =
      tickets.ticket_id +
      "-" +
      padStart(index + "", 3, "0") +
      `-day_${key === 1 ? "one" : "two"}_attendance`;
    console.log(keyReturn);
    return keyReturn;
  };

  console.log(scannedTix);

  return (
    <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
      <NavLink
        to={"/scan-code"}
        aria-label=""
        className="inline-flex mb-8 items-center text-sm font-semibold transition-colors duration-200 text-blue-accent-400 hover:text-blue-800"
      >
        <ArrowBackIcon style={{ marginRight: "12px" }} /> Go to code scanner
      </NavLink>
      <br />

      <NavLink
        to={"/"}
        aria-label=""
        className="inline-flex mb-8 items-center text-sm font-semibold transition-colors duration-200 text-blue-accent-400 hover:text-blue-800"
      >
        <ArrowBackIcon style={{ marginRight: "12px" }} /> Go to List
      </NavLink>
      <br />

      {id.length === 10 && (
        <>
          <div className=" grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
            <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
              <div className="relative p-5 bg-white rounded-sm">
                <div>
                  <span className="text-xs text-gray-700 uppercase ">
                    Name under this ticket
                  </span>
                </div>
                <span className="text-lg font-semibold">
                  {scannedTix.full_name}
                </span>
              </div>
            </div>
            <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
              <div className="relative p-5 bg-white rounded-sm">
                <div>
                  <span className="text-xs text-gray-700 uppercase ">
                    Scanned Ticket ID
                  </span>
                </div>
                <span className="text-lg font-semibold">{id}</span>
              </div>
            </div>
            <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
              <div className="relative p-5 bg-white rounded-sm">
                <div>
                  <span className="text-xs text-gray-700 uppercase ">
                    Status
                  </span>
                </div>
                <span className="text-lg font-semibold">{tickets.status}</span>
              </div>
            </div>
            <div className="relative p-px overflow-hidden transition duration-300 transform border border-dashed border-gray-500 rounded shadow-sm group">
              <div className="relative p-5 bg-white rounded-sm">
                <div>
                  <span className="text-xs text-gray-700 uppercase ">
                    Total No of Tickets
                  </span>
                </div>
                <span className="text-lg font-semibold">
                  {tickets.no_of_tickets}
                </span>
              </div>
            </div>
          </div>
          <header className="bg-gray-50 mb-8">
            <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-12 sm:px-6 lg:px-8 grid lg:grid-cols-3 gap-4">
              <div>
                <div className="grid">
                  {" "}
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Contact
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                      {scannedTix?.contact_number || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Church Name
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                      {scannedTix?.church_name || "-"}
                      {scannedTix?.others_church_name
                        ? ", " + scannedTix?.others_church_name
                        : ""}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Location
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                      {scannedTix?.city || "-"}
                      {scannedTix?.province ? ", " + scannedTix?.province : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="border border-gray-6300 p-8 shadow-lg shadow-neutral-300 cursor-pointer"
                onClick={() => handleOpenConfirmDialog(1)}
              >
                <p className="font-sans font-semibold text-gray-900 lg:text-lg text-md lg:max-w-md mb-4">
                  Day One
                </p>
                <div className="font-sans text-gray-900 lg:text-md lg:max-w-md mb-4">
                  {scannedTix?.attendance?.[
                    getDayKey(~~id.split("-")[1], 1)
                  ] ? (
                    <div>
                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                        Scanned By
                      </p>
                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                        {
                          scannedTix?.attendance?.[
                            getDayKey(~~id.split("-")[1], 1)
                          ]?.approvedBy
                        }
                      </p>
                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                        Date Scanned
                      </p>
                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                        {scannedTix?.attendance?.[
                          getDayKey(~~id.split("-")[1], 1)
                        ]?.dateScanned?.seconds &&
                          new Date(
                            scannedTix?.attendance?.[
                              getDayKey(~~id.split("-")[1], 1)
                            ]?.dateScanned?.seconds * 1000
                          )
                            .toISOString()
                            .split("T")[0] +
                            " - " +
                            new Date(
                              scannedTix?.attendance?.[
                                getDayKey(~~id.split("-")[1], 1)
                              ]?.dateScanned?.seconds * 1000
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            })}
                      </p>
                    </div>
                  ) : (
                    "Click to add attendance"
                  )}
                </div>
              </div>
              <div
                className="border border-gray-6300 p-8 shadow-lg shadow-neutral-300 cursor-pointer"
                onClick={() => handleOpenConfirmDialog(2)}
              >
                <p className="font-sans font-semibold text-gray-900 lg:text-md lg:max-w-md mb-4">
                  Day Two
                </p>
                <div className="font-sans text-gray-900 lg:text-md lg:max-w-md mb-4">
                  {scannedTix?.attendance?.[
                    getDayKey(~~id.split("-")[1], 2)
                  ] ? (
                    <div>
                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                        Scanned By
                      </p>
                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                        {
                          scannedTix?.attendance?.[
                            getDayKey(~~id.split("-")[1], 2)
                          ]?.approvedBy
                        }
                      </p>
                      <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                        Date Scanned
                      </p>
                      <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md mb-4">
                        {scannedTix?.attendance?.[
                          getDayKey(~~id.split("-")[1], 2)
                        ]?.dateScanned?.seconds &&
                          new Date(
                            scannedTix?.attendance?.[
                              getDayKey(~~id.split("-")[1], 2)
                            ]?.dateScanned?.seconds * 1000
                          )
                            .toISOString()
                            .split("T")[0] +
                            " - " +
                            new Date(
                              scannedTix?.attendance?.[
                                getDayKey(~~id.split("-")[1], 2)
                              ]?.dateScanned?.seconds * 1000
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            })}
                      </p>
                    </div>
                  ) : (
                    "Click to add attendance"
                  )}
                </div>
              </div>
            </div>
          </header>
        </>
      )}

      <header className="">
        <div className="max-w-screen-xl mx-auto sm:pb-8">
          <div className="sm:justify-between sm:items-center md:flex">
            <div className="text-center sm:text-left">
              <div className="flex flex-col lg:justify-between lg:flex-row">
                <h2 className="max-w-lg font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none group">
                  <span className="inline-block">
                    All tickets under this order:
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {Array.from({ length: tickets.no_of_tickets }, (_, index) => (
          <div
            key={index}
            className={`mb-2 border-solid hover:border-gray-400 cursor-pointer border-2 relative p-5 bg-white rounded-sm  w-full ${
              tickets.ticket_id === id
                ? "border-green-500 hover:border-green-700"
                : ""
            }`}
            onClick={() => {
              scrollToTop();
              navigate(
                "/scan-code/" +
                  tickets.ticket_id +
                  "-" +
                  padStart(index + 1 + "", 3, "0")
              );
            }}
          >
            <div className="pr-4 flex flex-col md:flex-row md:flex-wrap  lg:flex-nowrap gap-8">
              <div>
                <div className="max-w-lg mt-0 font-sans text-md font-bold tracking-tight text-gray-900 sm:leading-none group">
                  <div className=" mb-1 sm:mb-2 grid grid-cols-2 justify-between items-center">
                    <div>
                      Ticket ID:{" "}
                      {tickets.ticket_id +
                        "-" +
                        padStart(index + 1 + "", 3, "0")}{" "}
                    </div>
                    <div className="font-sans font-normal">
                      {tickets?.attendance?.[getDayKey(index + 1, 1)] && (
                        <Chip
                          size="small"
                          icon={<CheckCircle />}
                          label="Day One"
                          color="success"
                        />
                      )}{" "}
                      {tickets?.attendance?.[getDayKey(index + 1, 2)] && (
                        <Chip
                          size="small"
                          color="success"
                          icon={<CheckCircle />}
                          label="Day Two"
                        />
                      )}
                    </div>
                  </div>
                  <div className="h-0.5 mb-2 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      full name
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.full_name || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Age Group
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.age_group || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Contact
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.contact_number || "-"}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Church Name
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.church_name || "-"}
                      {tickets?.others_church_name
                        ? ", " + tickets?.others_church_name
                        : ""}
                    </p>
                  </div>
                  <div className="grid">
                    <p className="font-sans uppercase font-normal text-gray-800 text-xs lg:max-w-md">
                      Location
                    </p>
                    <p className="font-sans font-semibold text-gray-900 lg:text-sm lg:max-w-md">
                      {tickets?.city || "-"}
                      {tickets?.province ? ", " + tickets?.province : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={confirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="max-w-lg mt-2 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-xl sm:leading-none group">
            <span className="inline-block mb-1 sm:mb-4">Confirmation</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-accent-400 scale-x-30 group-hover:scale-x-100" />
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"div"}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <p className="font-sans font-normal text-gray-800 text-md lg:max-w-md">
              <b>{confirmDialogData?.isDeletion ? "Remove" : "Add"}</b> the
              attendance of "{scannedTix.full_name}" ({id.split("-")[1]})for{" "}
              <b>Day {confirmDialogData?.dayString}</b>?
              <br /> <br />
              Ticket ID: {id}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <span
            tabIndex={0}
            className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border text-blue-accent-400 hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          >
            Back
          </span>
          <span
            tabIndex={0}
            className={`cursor-pointer inline-flex items-center justify-center h-10 px-4 py-0 font-small tracking-wide  transition duration-200 rounded-md border bg-blue-accent-400 text-white hover:border-blue-accent-700 focus:shadow-outline focus:outline-none`}
            onClick={() => handleSubmit(confirmDialogData?.day)}
          >
            {submitText}
          </span>
        </DialogActions>
      </Dialog>
    </div>
  );
}
